<template>
    <v-form @submit.prevent="submitSignUpForm(signUpForm)">
        <portal to="auth-layout-title">
            Login
        </portal>

        <div class="space-y-default">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-default">
                <v-form-group>
                    <v-form-label>
                        Name
                    </v-form-label>

                    <v-form-input v-model="signUpForm.name" type="text" name="name" required :disabled="isLoading" />
                </v-form-group>

                <v-form-group>
                    <v-form-label>
                        Discord Name
                    </v-form-label>

                    <v-form-input v-model="signUpForm.discord_username" type="text" name="discord_name" :disabled="isLoading" />
                </v-form-group>
            </div>

            <v-form-group>
                <v-form-label for="email">
                    Email
                </v-form-label>

                <v-form-input v-model="signUpForm.email" type="email" name="email" required :disabled="isLoading" />
            </v-form-group>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-default">
                <v-form-group>
                    <v-form-label for="password">
                        Password
                    </v-form-label>

                    <v-form-input v-model="signUpForm.password" type="password" name="password" required :disabled="isLoading" />
                </v-form-group>

                <v-form-group>
                    <v-form-label for="password_confirmation">
                        Confirm Password
                    </v-form-label>

                    <v-form-input v-model="signUpForm.password_confirmation" type="password" name="password_confirmation" required :disabled="isLoading" />
                </v-form-group>
            </div>

            <v-form-group>
                <v-form-label for="email">
                    Code
                </v-form-label>

                <v-form-input v-model="signUpForm.code" type="text" name="code" required :disabled="isLoading" />
            </v-form-group>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-8">
                <v-button type="submit" class="order-2 sm:order-1 w-full" :disabled="isLoading">
                    Sign Up
                </v-button>

                <v-button :to="{ name: 'auth.login' }" color="dark" class="order-2 sm:order-1 w-full text-center">
                    Back to Login
                </v-button>
            </div>
        </div>
    </v-form>
</template>

<script>
export default {
    data() {
        return {
            signUpForm: {
                name: null,
                discord_username: null,
                email: null,
                password: null,
                password_confirmation: null,
                code: null,
            },
        };
    },
    methods: {
        submitSignUpForm(payload) {
            this.toggleLoading();

            this.$oauth.register(payload)
                .then((response) => {
                    setTimeout(() => {
                        this.$router.push({ name: "auth.login" });
                        this.toggleLoading();
                        this.$eventBus.$emit("response", response);
                    }, 500);
                })
                .catch((error) => {
                    this.$eventBus.$emit("error", error);
                    this.toggleLoading();
                });
        },
    },
};
</script>
